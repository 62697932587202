<template>
    <div class="dataStatis">
        <span v-show="!showDetail" style="float:left;padding-top:20px;padding-left:20px;" class="fontW">{{showTitle}}</span>
        <span v-show="!showDetail" @click="showDetail=true" style="float:right;padding-top:20px;padding-right:20px;" class="cSkyBlue fontW cursorP">返回</span>
        <div v-if="showDetail">
            <div class="statisList flex alignCenter spacebetween">
                <div class="statis">
                    <p class="statisTitle">
                        课程总数量
                    </p>
                    <span class="c33">{{allCensus.course_count}}</span>
                    <!-- <a class="cursorP">点击查看统计</a> -->
                </div>
                <div class="statis">
                    <p class="statisTitle">
                        观看总次数 
                    </p>
                    <span class="c33">{{allCensus.look_count}}</span>
                    <!-- <a class="cursorP">点击查看统计</a> -->
                </div>
                <div class="statis">
                    <p class="statisTitle">
                        观看总人数 
                    </p>
                    <span class="c33">{{allCensus.look_count_people}}</span>
                    <!-- <a class="cursorP">点击查看统计</a> -->
                </div>
                <div class="statis">
                    <p class="statisTitle">
                        评论总数
                    </p>
                    <span class="c33">{{allCensus.comment_count}}</span>
                    <!-- <a class="cursorP">点击查看统计</a> -->
                </div>
                <div class="statis">
                    <p class="statisTitle">
                        用户地域统计
                    </p>
                    <span class="c33">{{allCensus.province_count}}</span>
                    <!-- <a class="cursorP">点击查看统计</a> -->
                </div>
            </div>
                <div class="clear"></div>
            <div class="tables flex spacebetween">
                <div class="table1">
                    <p>用户地域统计</p>
                    <el-table
                        :data="areaCensus"
                        :header-cell-style="{textAlign:'center',background:'#47D7E3',color:'#fff',fontSize: '15px',fontWeight:'normal'}"
                        :cell-style="{textAlign:'center', color:'#484848',fontSize: '15px', bordeBottom:'1px dashed #D8D8D8'}"
                        height="569"
                        style="width: 354px;margin-top:24px;border:1px solid #D8D8D8">
                        <el-table-column
                        prop="province"
                        label="省份">
                        </el-table-column>
                        <el-table-column
                        prop="count"
                        label="数量"
                        width="100">
                        </el-table-column>
                        <el-table-column
                        prop="rate"
                        label="占比"
                        width="100">
                        </el-table-column>
                    </el-table>
                </div>
                <div class="table2">
                    <p>课程统计</p>
                    <el-table
                        :data="courseCensus"
                        :header-cell-style="{textAlign:'center',background:'#47D7E3',color:'#fff',fontSize: '15px',fontWeight:'normal'}"
                        :cell-style="{textAlign:'center', color:'#484848',fontSize: '15px', bordeBottom:'1px dashed #D8D8D8'}"
                        height="569"
                        style="width: 542px;margin-top:24px;border:1px solid #D8D8D8">
                        <el-table-column
                        prop="title"
                        label="课程名称">
                        </el-table-column>
                        <el-table-column
                        prop="look_count"
                        label="总观看次数"
                        width="100">
                        </el-table-column>
                        <el-table-column
                        prop="comment_count"
                        label="评论总数"
                        width="100">
                        </el-table-column>
                        <el-table-column
                        prop="score"
                        label="课程评分"
                        width="80">
                        </el-table-column>
                        <el-table-column
                        label="详情"
                        width="80"
                        style="cursor:pointer;color: #47D7E3;">
                        <template slot-scope="scope">
                            <span @click="read(scope.row)" class="cursorP" style="color: #47D7E3;">查看</span>
                        </template>
                        </el-table-column>
                    </el-table>
                </div>
            </div>
        </div>
        <div v-else style="width:98%;float:right;">
            <div class="statisList flex alignCenter spacebetween" style="width:100%;">
                <div style="text-align:left;">
                    <div class="kctj fontW" style="font-size:15px;color:#484848;line-height:22px;">课程统计</div>
                    <div class=" flex alignCenter spacebetween" style="margin-top:12px;width:543px;">
                        <div class="statis" style="height:111px;">
                            <p class="statisTitle">
                                观看总次数
                            </p>
                            <span class="c33">{{courseInfo.look_count}}</span>
                            <!-- <a class="cursorP">点击查看统计</a> -->
                        </div>
                        <div class="statis" style="height:111px;">
                            <p class="statisTitle">
                                观看总人数 
                            </p>
                            <span class="c33">{{courseInfo.look_count_people}}</span>
                            <!-- <a class="cursorP">点击查看统计</a> -->
                        </div>
                        <div class="statis" style="height:111px;">
                            <p class="statisTitle">
                                评论总数 
                            </p>
                            <span class="c33">{{courseInfo.comment_count}}</span>
                            <!-- <a class="cursorP">点击查看统计</a> -->
                        </div>
                    </div>
                </div>
                <div style="text-align:left;margin-left: 12px;">
                    <div class="kctj fontW" style="font-size:15px;color:#484848;line-height:22px;">课程评分</div>
                    <div class="" style="margin-top:12px;width:543px;">
                        <div class="kcpf flex flexColumn spacearound">
                            <p>课程先进性：{{courseInfo.advanced_avg}}</p>
                            <p>课程实用性：{{courseInfo.utility_avg}}</p>
                            <p>课程表现性：{{courseInfo.expressive_avg}}</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="table1" style="width:897px;">
                <p style="margin-top:31px;">地域分布</p>
                <el-table
                    :data="courseInfo.province_info"
                    :header-cell-style="{textAlign:'center',background:'#47D7E3',color:'#fff',fontSize: '15px',fontWeight:'normal'}"
                    :cell-style="{textAlign:'center', color:'#484848',fontSize: '15px', bordeBottom:'1px dashed #D8D8D8'}"
                    height="569"
                    style="width: 897px;margin-top:24px;border:1px solid #D8D8D8">
                    <el-table-column
                    prop="province"
                    label="省份">
                    </el-table-column>
                    <el-table-column
                    prop="count"
                    label="数量"
                    >
                    </el-table-column>
                    <el-table-column
                    prop="rate"
                    label="占比"
                    >
                    </el-table-column>
                </el-table>
            </div>
        </div>
    </div>
</template>
<script>
import { census, area_census, course_census, course_info_census } from '@/utils/Api/userList'
export default {
    name:'dataStatis',
    data(){
        return{
            allCensus:{},
            areaCensus:[],
            courseCensus:[],
            courseInfo:{},
            showDetail: true,
            showTitle:''
        }
    },
    created(){
        this.initial()
    },
    methods:{
        async initial(){
            let allCensus = await census()
            this.allCensus = allCensus
            let areaCensus = await area_census()
            this.areaCensus = areaCensus
            let courseCensus = await course_census()
            this.courseCensus = courseCensus
        },
        async read(data){
            console.log(data)
            this.showTitle = data.title
            let courseInfo = await course_info_census({course_id: data.id})
            this.courseInfo = courseInfo
            this.showDetail = false
        }
    }
}
</script>
<style lang="scss" scoped>
.kcpf{
    width: 342px;
    height: 111px;
    opacity: 1;
    background: #f7f7f7;
    font-size: 15px;
    color: #797979;
    p{
        display: inline-block;
        margin-left: 43px;
    }
}
.tables{margin-top: 27px;width: 98%;
float: right;}
.table1, .table2{
    text-align: left;
    width: 354px;
    font-size: 16px;
    color: #242424;
    p{font-weight: bold;}
}
.table2{width: 542px;}
td{
    border-bottom: 1px dashed #D8D8D8!important;
}
.dataStatis{
    .statisList{
        width: 98%;
        margin-top: 37px;
        float: right;
        .statis{
            width: 145px;
            height: 130px;
            overflow: hidden;
            background: #f7f7f7;
            color: #717171;
            padding-left: 20px;
            text-align: left;
            &:nth-child(2){
                .statisTitle::before{background: #FDB24C!important;}
            }
            &:nth-child(3){
                .statisTitle::before{background: #FD576A!important;}
            }
            &:nth-child(4){
                .statisTitle::before{background: #5798FC !important;}
            }
            &:nth-child(5){
                .statisTitle::before{background: #9E4CFD!important;}
            }
            a{
                font-size: 14px;
                color: #1782FF;
                display: inline-block;
                margin-top: 15px;
                margin-left: 18px;
                width: 100%;
            }
            span{
                font-size: 22px;
                display: inline-block;
                margin-left: 18px;
                margin-top: 12px;
                text-align: left;
            }
            .statisTitle{
                font-size: 15px;
                margin-top: 20px;
                position: relative;
                &::before{
                    content: '';
                    display: inline-block;
                    width: 12px;
                    height: 12px;
                    background: #47D7E3;
                    left: 0px;
                    top: 0;
                }
            }
        }
    }
}
</style>